<template>
  <v-app>
    <v-app-bar
      dense
      fixed
      app
      dark
      color="primary">
      <v-toolbar-title>Admin Samsys</v-toolbar-title>
      <v-spacer />
      <v-toolbar-items class="hidden-sm-and-down">
        <v-btn
          text
          v-for="item in menuListFiltered"
          :key="item.name"
          :to="item.url"
          >{{ item.name }}</v-btn
        >

        <v-menu
          offset-y
          :nudge-width="180">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              text
              v-bind="attrs"
              v-on="on">
              <v-icon>mdi-account</v-icon>
            </v-btn>
          </template>
          <v-card>
            <v-list>
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title>{{ me.firstname }} {{ me.lastname }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list>
              <v-list-item @click="logout">
                <v-list-item-title>Se déconnecter</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </v-menu>
      </v-toolbar-items>
      <v-app-bar-nav-icon
        class="hidden-md-and-up"
        @click="navDrawer = true" />
    </v-app-bar>

    <v-navigation-drawer
      right
      app
      temporary
      v-model="navDrawer">
      <v-list nav>
        <v-list-item
          v-for="item in menuListFiltered"
          :key="item.name"
          link
          :to="item.url">
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ item.name }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-divider></v-divider>

      <v-list>
        <v-list-item @click="logout">
          <v-list-item-icon>
            <v-icon>mdi-account</v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Se déconnecter</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Sizes your content based upon application components -->
    <v-main class="fulls">
      <!-- Provides the application the proper gutter -->
      <!-- If using vue-router -->
      <router-view class="fulls" />
    </v-main>
  </v-app>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'

export default {
  data() {
    return {
      fav: true,
      menu: false,
      message: false,
      hints: true,
      navDrawer: false,
      menuList: [
        { name: 'dashboard', url: '/', icon: 'mdi-view-dashboard' },
        { name: 'counters', url: '/counters', icon: 'mdi-router-wireless' },
        { name: 'bleTags', url: '/bleTags', icon: 'mdi-tag', isSamsys: true },
        // { name: 'machines', url: '/machines', icon: 'mdi-truck' },
        { name: 'users', url: '/users', icon: 'mdi-account' },
        { name: 'clusters', url: '/clusters', icon: 'mdi-account-group' },
        // { name: 'maps', url: '/maps', icon: 'mdi-map' },
        { name: 'params', url: '/params', icon: 'mdi-tools' },
        { name: 'tools', url: '/tools', icon: 'mdi-pencil-outline' },
        // { name: 'invoices', url: '/invoicing', icon: 'mdi-cash', isSamsys: true },
        { name: 'Renew Bot', url: '/renewBot', icon: 'mdi-cash', isSamsys: true },
      ],
    }
  },
  computed: {
    ...mapGetters(['me', 'isSamsys']),
    menuListFiltered() {
      return this.menuList.filter((item) => {
        if (item.isSamsys) {
          return this.isSamsys
        }
        return true
      })
    },
  },
  async created() {
    await this.getMe()
    this.getClusters()
  },
  methods: {
    ...mapActions(['removeAdminToken', 'getMe', 'getClusters']),
    logout() {
      this.removeAdminToken()
      this.$router.push('/login')
    },
  },
}
</script>
